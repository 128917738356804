import React from "react";
import { Card, Col, Divider, Row, Typography, Grid, Layout } from "antd";
import aboutImage from "../Assets/about-image-01.png";
import aboutSection02 from "../Assets/about-section-02.webp";
import aboutSection04 from "../Assets/about-section-04.png";
import aboutSection05 from "../Assets/about-section-05.png";
import aboutSection06 from "../Assets/about-section-06.webp";
import darLogo from "../Assets/dar-logo.png";
import GagarinMap from "./GagarinMap";
import footerImage from "../Assets/footer-section.png";
import { useTranslation } from "react-i18next";

const { Footer } = Layout;
const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const AboutPage = () => {
  const { xs } = useBreakpoint();
  const { t } = useTranslation();

  return (
    <div className="about-page">
      <Row
        className="section"
        justify="space-between"
        align="middle"
        style={{
          backgroundImage: `url(${aboutImage})`,
          backgroundColor: "#F49FC4",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "bottom",
        }}
      >
        <Col xs={{ span: 0 }} lg={{ span: 12 }}></Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Title level={1}>Gagarin Project</Title>
          <Divider style={{ borderTop: "3px solid rgb(255 255 255)" }} />
          <Paragraph>{t("About.Text1")}</Paragraph>
        </Col>
      </Row>
      <Row
        className="section"
        justify="space-between"
        align="middle"
        style={{
          backgroundImage: `url(${aboutSection02})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: xs ? "top" : "center",
          minHeight: xs ? "250px" : "100vh",
        }}
      ></Row>
      <Row
        className="section"
        justify="space-between"
        align="middle"
        style={{
          padding: 0,
          height: xs ? "496px" : "auto",
          overflowX: xs ? "scroll" : "hidden",
          overflowY: "hidden",
          minHeight: xs ? "250px" : "auto",
          marginBottom: "-10px",
        }}
      >
        <div
          style={{
            overflowX: xs ? "scroll" : "hidden",
            width: xs ? "auto" : "100%",
          }}
        >
          <GagarinMap />
        </div>
      </Row>
      <Row
        className="section"
        justify="space-between"
        align="middle"
        style={{
          backgroundColor: "#FFC708",
          backgroundImage: `url(${aboutSection04})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "right",
          alignContent: "center",
        }}
      >
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <img src={darLogo} alt="darlogo" />
          <Divider style={{ borderTop: "none", marginTop: 70 }} />
          <Title level={2}>{t("About.DARFoundation")}</Title>
          <Divider style={{ borderTop: "3px solid rgb(255 255 255)" }} />
          <Paragraph className="big-text">{t("About.DARText1")}</Paragraph>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Divider
            style={{
              borderTop: "none",
              marginTop: xs ? 15 : 300,
              display: xs ? "none" : "flex",
            }}
          />
          <Divider
            style={{
              borderTop: "3px solid rgb(255 255 255)",
              display: xs ? "none" : "flex",
            }}
          />
          <Paragraph className="big-text">{t("About.DARText2")}</Paragraph>
        </Col>
      </Row>
      <Divider style={{ borderTop: "1px solid #FFC708", margin: 0 }} />
      <Row
        className="section"
        justify="space-between"
        align="top"
        style={{
          backgroundColor: "#FFC708",
          backgroundImage: `url(${aboutSection05})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "right bottom",
          minHeight: "auto",
        }}
      >
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Divider style={{ borderTop: "3px solid rgb(255 255 255)" }} />
          <Paragraph>{t("About.DARText3")}</Paragraph>
          <Divider style={{ borderTop: "none", marginTop: 30 }} />
          <Paragraph style={{ fontWeight: 900 }}>
            {t("About.DARText5")}
          </Paragraph>
          <Paragraph>• {t("About.DARText6")}</Paragraph>
          <Paragraph>• {t("About.DARText7")}</Paragraph>
          <Paragraph>• {t("About.DARText8")}</Paragraph>
          <Paragraph>• {t("About.DARText9")}</Paragraph>
          <Paragraph>• {t("About.DARText10")}</Paragraph>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Divider style={{ borderTop: "3px solid rgb(255 255 255)" }} />
          <Paragraph>{t("About.DARText4")}</Paragraph>
        </Col>
      </Row>
      <Row
        className="section"
        justify="space-between"
        align="top"
        style={{
          backgroundColor: "#FFC708",
          backgroundImage: `url(${aboutSection06})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "right bottom",
          paddingTop: "96px",
          paddingBottom: "64px",
        }}
      >
        <Col xs={{ span: 24 }} lg={{ span: 22 }}>
          <Card
            bordered={false}
            title={t("About.Team")}
            style={{
              backgroundColor: "#293B97",
              padding: xs ? "12px" : "50px 140px",
            }}
            className="team-card"
          >
            <Row gutter={8}>
              <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <Paragraph>
                  <Paragraph style={{ fontWeight: 900, marginBottom: 0 }}>
                    {t("About.ArsenAgadzhanyan")}
                  </Paragraph>
                  {t("About.ProjectFounder")}
                </Paragraph>
                <Paragraph>
                  <Paragraph style={{ fontWeight: 900, marginBottom: 0 }}>
                    {t("About.MargaritaGevorgyan")}
                  </Paragraph>
                  {t("About.ExecutiveDirector")}
                </Paragraph>
                <Paragraph>
                  <Paragraph style={{ fontWeight: 900, marginBottom: 0 }}>
                    {t("About.SarhatPetrosyan")}
                  </Paragraph>
                  {t("About.ProjectManager")}
                </Paragraph>
                <Paragraph>
                  <Paragraph style={{ fontWeight: 900, marginBottom: 0 }}>
                    {t("About.AniAghajanyan")}
                  </Paragraph>{" "}
                  {t("About.ProjectOfficer")}
                </Paragraph>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <Paragraph>
                  <Paragraph style={{ fontWeight: 900, marginBottom: 0 }}>
                    {t("About.AramGharibyan")}
                  </Paragraph>{" "}
                  {t("About.ProgramsAdvisor")}
                </Paragraph>
                <Paragraph>
                  <Paragraph style={{ fontWeight: 900, marginBottom: 0 }}>
                    {t("About.NuneManukyan")}
                  </Paragraph>
                  {t("About.TourismDevelopmentManager")}
                </Paragraph>
                <Paragraph>
                  <Paragraph style={{ fontWeight: 900, marginBottom: 0 }}>
                    {t("About.ArevikAbrahamyan")}
                  </Paragraph>
                  {t("About.TourismProjectsCoordinator")}
                </Paragraph>
                <Paragraph>
                  <Paragraph style={{ fontWeight: 900, marginBottom: 0 }}>
                    {t("About.AlvardAvetisyan")}
                  </Paragraph>
                  {t("About.EducationalProjectsCoordinator")}
                </Paragraph>
                <Paragraph>
                  <Paragraph style={{ fontWeight: 900, marginBottom: 0 }}>
                    {t("About.HaykBianjyan")}
                  </Paragraph>
                  {t("About.CulturalProjectsCoordinator")}
                </Paragraph>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                <Paragraph>
                  <Paragraph style={{ fontWeight: 900, marginBottom: 0 }}>
                    {t("About.VladimirBabayan")}
                  </Paragraph>{" "}
                  {t("About.LegalAdvisor")}
                </Paragraph>
                <Paragraph>
                  <Paragraph style={{ fontWeight: 900, marginBottom: 0 }}>
                    {t("About.SedaGrigoryan")}
                  </Paragraph>
                  {t("About.CommunicationsCoordinator")}
                </Paragraph>
                <Paragraph>
                  <Paragraph style={{ fontWeight: 900, marginBottom: 0 }}>
                    {t("About.AngelikaTamaryan")}
                  </Paragraph>
                  {t("About.ProjectAssistant")}
                </Paragraph>
                <Paragraph>
                  <Paragraph style={{ fontWeight: 900, marginBottom: 0 }}>
                    {t("About.AnahitNahapetyan")}
                  </Paragraph>
                  {t("About.ProjectAssistant")}
                </Paragraph>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row
        className="section"
        align="middle"
        style={{
          backgroundColor: "#E8E8E8",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "right bottom",
          display: "block",
          paddingTop: xs ? 32 : "25vh",
        }}
      >
        <Row>
          <Title level={2} style={{ color: "#262626" }}>
            {t("About.Partners")}
          </Title>
        </Row>
        <Row className="partners" align="top" justify="space-between">
          <Col xs={{ span: 24 }} lg={{ span: 7 }}>
            <Divider style={{ borderTop: "3px solid #262626" }} />
            <Paragraph
              style={{
                textTransform: "uppercase",
                fontWeight: 700,
                marginBottom: 20,
              }}
            >
              {t("About.MoE")}
            </Paragraph>
            <Paragraph
              style={{
                textTransform: "uppercase",
                fontWeight: 700,
                marginBottom: 0,
              }}
            >
              {t("About.Conceptofterritorialdevelopment")}
            </Paragraph>
            <Paragraph style={{ marginBottom: 20 }}>
              {t("About.MVRDV")}
            </Paragraph>
            <Paragraph></Paragraph>
            <Paragraph
              style={{
                textTransform: "uppercase",
                fontWeight: 700,
                marginBottom: 0,
              }}
            >
              {t("About.EconomicConsultant")}
            </Paragraph>
            <Paragraph style={{ marginBottom: 20 }}>
              {t("About.CIVITTA")}
            </Paragraph>
            <Paragraph></Paragraph>
            <Paragraph
              style={{
                textTransform: "uppercase",
                fontWeight: 700,
                marginBottom: 0,
              }}
            >
              {t("About.TermsofReference")}
            </Paragraph>
            <Paragraph style={{ marginBottom: 20 }}>
              {t("About.Storaket")}
            </Paragraph>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 7 }}>
            <Divider style={{ borderTop: "3px solid #262626" }} />
            <Paragraph
              style={{
                textTransform: "uppercase",
                fontWeight: 700,
                marginBottom: 0,
              }}
            >
              {t("About.TangibleHeritageResearch")}
            </Paragraph>
            <Paragraph style={{ marginBottom: 20 }}>
              {t("About.Mazaz")}
            </Paragraph>
            <Paragraph
              style={{
                textTransform: "uppercase",
                fontWeight: 700,
                marginBottom: 0,
              }}
            >
              {t("About.IntangibleHeritageResearch")}
            </Paragraph>
            <Paragraph style={{ marginBottom: 20 }}>
              {t("About.Hazarashen")}
            </Paragraph>
            <Paragraph></Paragraph>
            <Paragraph
              style={{
                textTransform: "uppercase",
                fontWeight: 700,
                marginBottom: 0,
              }}
            >
              {t("About.BiodiversityResearch")}
            </Paragraph>
            <Paragraph>
              {t("About.ArmenGasparyan")}
              <br />
              {t("About.IvanGabrielyan")}
              <br />
              {t("About.ArthurAlaverdyan")}
              <br />
              {t("About.VasilAnanyan")}
            </Paragraph>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 7 }}>
            <Divider style={{ borderTop: "3px solid #262626" }} />
            <Paragraph
              style={{
                textTransform: "uppercase",
                fontWeight: 700,
                marginBottom: 0,
              }}
            >
              {t("About.HydrologicalResearch")}
            </Paragraph>
            <Paragraph style={{ marginBottom: 20 }}>
              {t("About.AlexanderArakelyan")}
            </Paragraph>
            <Paragraph
              style={{
                textTransform: "uppercase",
                fontWeight: 700,
                marginBottom: 0,
              }}
            >
              {t("About.GIS")}
            </Paragraph>
            <Paragraph style={{ marginBottom: 20 }}>
              {t("About.ArtakPiloyan")}
            </Paragraph>
            <Paragraph style={{ marginBottom: 20 }}>
              {t("About.Geomapis")}
            </Paragraph>
          </Col>
        </Row>
      </Row>
      <Footer>
        <Row
          className="section"
          style={{
            backgroundColor: "#ffffff",
            backgroundImage: `url(${footerImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            minHeight: xs ? "calc(100vh - 80px)" : "calc(100vh - 72px)",
          }}
          justify="space-between"
          align="middle"
        >
          <Col xs={{ span: 24 }} lg={{ span: 10 }}></Col>
          <Col xs={{ span: 24 }} lg={{ span: 10 }}>
            <Title level={2}>{t("Global.Contacts")}</Title>
            <Divider style={{ borderTop: "3px solid rgb(38, 38, 38)" }} />
            <Paragraph>
              <a href="mailto:mail@gagarinproject.am">mail@gagarinproject.am</a>
            </Paragraph>
            <Paragraph>
              <a href="tel:+37410501500">+374 10 501500</a>
            </Paragraph>
          </Col>
        </Row>
      </Footer>
    </div>
  );
};

export default AboutPage;

import React, { useState, useEffect } from "react";
import { Col, Layout, Row, Drawer, Button, Grid } from "antd";
import HomePage from "./Components/HomePage";
import Logo from "./Assets/Logo.svg";
import MobileLogo from "./Assets/Mobile-Logo.svg";
import { Route, Link, useLocation, Redirect } from "react-router-dom";
import VisionPage from "./Components/VisionPage";
import NotFoundPage from "./Components/NotFoundPage";
import AboutPage from "./Components/AboutPage";
import LibraryPage from "./Components/LibraryPage";
import BlogArchive from "./Components/BlogArchive";
import { CSSTransition } from "react-transition-group";
import ScrollToTop from "./Components/ScrollToTop";
import MainMenu from "./Components/MainMenu";
import MVRDVPage from "./Components/MVRDV";

const { Header, Content } = Layout;
const { useBreakpoint } = Grid;

const routes = [
  { path: "/", exact: true, name: "HomePage", Component: HomePage },
  { path: "/about", exact: true, name: "About", Component: AboutPage },
  { path: "/vision", exact: true, name: "Vision", Component: VisionPage },
  { path: "/archive", exact: true, name: "Archive", Component: BlogArchive },
  { path: "/library", exact: true, name: "Library", Component: LibraryPage },
  { path: "/concept", exact: true, name: "MVRDV", Component: MVRDVPage},
  { path: "/404", exact: false, name: "NotFound", Component: NotFoundPage },
];

const App = () => {
  const location = useLocation();
  const { xs } = useBreakpoint();
  const currentKey = location.pathname.split("/")[1] || "/";
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [headerClass, setHeaderClass] = useState("header");

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onDrawerClose = () => {
    setDrawerVisible(false);
  };
  useEffect(() => {
    const onScroll = (e) => {
      if (window.pageYOffset > 100 && !xs) {
        setScrolled(true);
        setHeaderClass("header invert-menu");
      } else {
        setScrolled(false);
        setHeaderClass("header");
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [xs]);

  return (
    <Layout className="layout">
      <ScrollToTop />
      <Header className={headerClass}>
        <Row justify="space-between" align="middle">
          <Col xs={{ span: 12 }} lg={{ span: 6 }}>
            <div className="logo">
              <Link to="/">
                <img
                  className="desktop-logo"
                  src={
                    scrolled || currentKey === "archive" || currentKey === "404"
                      ? MobileLogo
                      : Logo
                  }
                  alt="logo"
                />
                <img className="mobile-logo" src={MobileLogo} alt="logo" />
              </Link>
            </div>
          </Col>
          <Col
            xs={{ span: 10 }}
            lg={{ span: 18 }}
            style={{ justifyContent: "flex-end", display: "flex" }}
          >
            <div className="desktop-menu">
              <MainMenu
                currentKey={currentKey}
                drawerVisible={drawerVisible}
                setDrawerVisible={setDrawerVisible}
                scrolled={scrolled}
                xs={xs}
              />
            </div>
            <Button className="barsMenu" type="primary" onClick={showDrawer}>
              <span className="barsBtn"></span>
            </Button>
            <Drawer
              title={
                <div className="logo">
                  <Link to="/" onClick={() => setDrawerVisible(false)}>
                    <img className="mobile-logo" src={MobileLogo} alt="logo" />
                  </Link>
                </div>
              }
              placement="right"
              onClose={onDrawerClose}
              visible={drawerVisible}
              width="100%"
            >
              <MainMenu
                currentKey={currentKey}
                drawerVisible={drawerVisible}
                setDrawerVisible={setDrawerVisible}
                scrolled={scrolled}
                xs={xs}
              />
            </Drawer>
          </Col>
          <Col xs={{ span: 0 }} lg={{ span: 0 }}>
            <div
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                backgroundColor: "#ffffff",
                textAlign: "center",
              }}
            >
              <span
                style={{
                  color: "#FFC708",
                  fontSize: "15px",
                  lineHeight: "60px",
                  textTransform: "capitalize",
                }}
              >
                Eng
              </span>
            </div>
          </Col>
        </Row>
      </Header>
      <Content>
        {routes
          .filter((x) => x.path !== "/")
          .some((x) => x.path === "/" + currentKey) || currentKey === "/" ? (
          routes.map(({ path, exact, Component }) => (
            <Route key={path} exact={exact} path={path}>
              {({ match }) => {
                return (
                  <CSSTransition
                    in={match != null}
                    timeout={700}
                    classNames="page"
                    unmountOnExit
                  >
                    <div className="page">
                      <Component />
                    </div>
                  </CSSTransition>
                );
              }}
            </Route>
          ))
        ) : (
          <Redirect to="/404" />
        )}
      </Content>
    </Layout>
  );
};

export default App;

import React, { useRef, useEffect } from "react";
import { Col, Divider, Row, Typography, Grid, Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import geghamavan from "../Assets/geghamavan-map.png";
import varser from "../Assets/varser-map.png";
import zovaber from "../Assets/zovaber-map.png";
import tsaghkunk from "../Assets/tsaghkunk-map.png";
import ddmashen from "../Assets/ddmashen-map.png";
import gagarin from "../Assets/gagarin-map.png";
import { useTranslation } from "react-i18next";

const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const CommunityPage = ({ communityId, setCommunityId }) => {
  const { xs } = useBreakpoint();
  const ref = useRef();
  const { t } = useTranslation();

  const communities = [
    {
      id: 0,
      area: 103,
      population: 1743,
      name: `${t("Home.Gagarin")}`,
      img: gagarin,
      color: "#293B97",
      titleColor: "#ffffff", //"#172257",
      overview: [
        `${t("Settlements.Gagarin1")}`,
        `${t("Settlements.Gagarin2")}`,
      ],
    },
    {
      id: 1,
      area: 31.28,
      population: 1875,
      name: `${t("Home.Geghamavan")}`,
      img: geghamavan,
      color: "#8F78B6",
      titleColor: "#ffffff", //"#172257",
      overview: [
        `${t("Settlements.Geghamavan1")}`,
        `${t("Settlements.Geghamavan2")}`,
      ],
    },
    {
      id: 2,
      area: 43.45,
      population: 2833,
      name: `${t("Home.Ddmashen")}`,
      img: ddmashen,
      color: "#FFC708",
      titleColor: "#ffffff", //"#172257",
      overview: [
        `${t("Settlements.Ddmashen1")}`,
        `${t("Settlements.Ddmashen2")}`,
      ],
    },
    {
      id: 3,
      area: 28.76,
      population: 1512,
      name: `${t("Home.Zovaber")}`,
      img: zovaber,
      color: "#F49FC4",
      titleColor: "#ffffff", //"#172257",
      overview: [
        `${t("Settlements.Zovaber1")}`,
        `${t("Settlements.Zovaber2")}`,
      ],
    },
    {
      id: 4,
      area: 87.5,
      population: 1026,
      name: `${t("Home.Tsaghkunk")}`,
      img: tsaghkunk,
      color: "#009547",
      titleColor: "#ffffff", //"#172257",
      overview: [
        `${t("Settlements.Tsaghkunk1")}`,
        `${t("Settlements.Tsaghkunk2")}`,
      ],
    },
    {
      id: 5,
      area: 36.59,
      population: 1911,
      name: `${t("Home.Varser")}`,
      img: varser,
      color: "#EF4B23",
      titleColor: "#ffffff", //"#172257",
      overview: [
        `${t("Settlements.Varser1")}`,
        `${t("Settlements.Varser2")}`,
      ],
    },
  ];

  useEffect(() => {
    if (ref && ref.current) {
      // this 'ref' has access to 'goTo', 'prev' and 'next'
      ref.current.goTo(communityId, false);
    }
  }, [ref, communityId]);

  const handleOnRightArrowClick = () => {
    if (communityId >= 0 && communityId < 5) {
      setCommunityId(communityId + 1);
    }
  };
  const handleOnLeftArrowClick = () => {
    if (communityId !== 0 && communityId < 6) {
      setCommunityId(communityId - 1);
    }
  };

  const handleChange = (newPosition) => {
    setCommunityId(newPosition);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 700,
    initialSlide: communityId,
    easing: "easeInCubic",
  };

  return (
    <div className="community-page">
      <Row justify="space-between" className="desktop-arrows">
        <Col>
          <LeftOutlined
            onClick={handleOnLeftArrowClick}
            className="arrow-left"
            style={{
              visibility: communityId === 0 ? "hidden" : "visible",
            }}
          />
        </Col>
        <Col>
          <RightOutlined
            onClick={handleOnRightArrowClick}
            className="arrow-right"
            style={{
              visibility: communityId === 5 ? "hidden" : "visible",
            }}
          />
        </Col>
      </Row>
      <Carousel {...settings} ref={ref} afterChange={handleChange}>
        {communities.map((community, index) => (
          <div className="community-page-inner" key={index}>
            <Row
              className="section"
              justify="center"
              align="top"
              style={{
                backgroundColor: community.color,
                minHeight: xs ? 0 : "40%",
                paddingTop: xs ? 65 : 160,
              }}
            >
              <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                <Title level={2} style={{ color: community.titleColor }}>
                  {t("Home.Settlements")}
                </Title>
                <Paragraph style={{ color: community.titleColor }}>
                  {t("Settlements.Area")} 179,45{" "}
                  <sup>{t("Settlements.sqkm")}</sup>
                  <br />
                  {t("Settlements.Population")} 10863
                </Paragraph>
              </Col>
              <Col
                xs={{ span: 24 }}
                lg={{ span: 10 }}
                style={{ textAlign: "right" }}
              ></Col>
            </Row>
            <Row
              className="section white-text"
              justify="center"
              align="top"
              style={{
                backgroundColor: community.color,
                alignContent: "flex-start",
              }}
            >
              <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                <Divider style={{ borderTop: "3px solid rgb(255 255 255)" }} />
                <Title level={4}>{community.name}</Title>
                <div
                  style={{
                    minHeight: "230px",
                    textAlign: xs ? "center" : "inherit",
                  }}
                >
                  <img src={community.img} alt={community.name} />
                </div>
                <Row justify="space-between" className="mobile-arrows">
                  <Col>
                    <LeftOutlined
                      onClick={handleOnLeftArrowClick}
                      className="arrow-left"
                      style={{
                        visibility: communityId === 0 ? "hidden" : "visible",
                      }}
                    />
                  </Col>
                  <Col>
                    <RightOutlined
                      onClick={handleOnRightArrowClick}
                      className="arrow-right"
                      style={{
                        visibility: communityId === 5 ? "hidden" : "visible",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 1 }}></Col>
              <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                <Divider style={{ borderTop: "3px solid rgb(255 255 255)" }} />
                <Paragraph style={{ fontWeight: 700 }}>
                  {t("Settlements.Area")} {community.area}
                  <sup>
                    {communityId === 0
                      ? t("Settlements.ha")
                      : t("Settlements.sqkm")}
                  </sup>
                  <br />
                  {t("Settlements.Population")} {community.population}
                </Paragraph>
                <Paragraph style={{ fontWeight: 700 }}>
                  {t("Settlements.HistoricalOverview")}
                </Paragraph>
                <ul>
                  {community.overview.map((row, index) => (
                    <li key={index}>{row}</li>
                  ))}
                </ul>
              </Col>
            </Row>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CommunityPage;

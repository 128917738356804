import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import Languages from "./Languages.json";
import { useTranslation } from "react-i18next";

const MainMenu = ({
  currentKey,
  drawerVisible,
  setDrawerVisible,
  scrolled,
  xs,
}) => {
  const { t, i18n } = useTranslation();
  const fontStyle = {
    color: drawerVisible
      ? "#8F78B6"
      : currentKey === "vision" ||
        currentKey === "about" ||
        currentKey === "archive" || currentKey === "404"
      ? "#293B97"
      : scrolled
      ? "#262626"
      : "#FFFFFF",
  };

  const underLineStyle = {
    content: "",
    position: "absolute",
    zIndex: -1,
    right: 0,
    width: 0,
    bottom: 0,
    height: xs ? 0 : "2px",
    transitionProperty: "width",
    transitionDuration: "0.3s",
    transitionTimingFunction: "ease-out",
    backgroundColor: fontStyle.color,
  };

  const handleLangSwitch = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Menu
      theme="dark"
      mode="inline"
      inlineCollapsed={false}
      defaultSelectedKeys={[`${currentKey}`]}
      style={{
        background: "transparent",
        display: drawerVisible ? "block" : "inline-flex",
      }}
      selectedKeys={[`${currentKey}`]}
    >
      <Menu.Item key="about" className="main-menu-item">
        <Link
          to="/about"
          style={fontStyle}
          onClick={() => setDrawerVisible(false)}
        >
          {t("Global.About")}
          <div className="underline-style" style={underLineStyle} />
        </Link>
      </Menu.Item>
      <Menu.Item key="vision" className="main-menu-item">
        <Link
          to="/vision"
          style={fontStyle}
          onClick={() => setDrawerVisible(false)}
        >
          {t("Global.Vision")}
          <div className="underline-style" style={underLineStyle} />
        </Link>
      </Menu.Item>
{/*       <Menu.Item key="archive" className="main-menu-item">
        <Link
          to="/archive"
          style={fontStyle}
          onClick={() => setDrawerVisible(false)}
        >
          {t("Global.Blog")}
          <div className="underline-style" style={underLineStyle} />
        </Link>
      </Menu.Item> */}
      <Menu.Item key="library" className="main-menu-item">
        <Link
          to="/library"
          style={fontStyle}
          onClick={() => setDrawerVisible(false)}
        >
          {t("Global.Library")}
          <div className="underline-style" style={underLineStyle} />
        </Link>
      </Menu.Item>
      <Menu.Item key="language" className="main-menu-item language-switch">
        <ul>
          {Languages.map((language) => {
            return (
              <li
                size="small"
                onClick={() => handleLangSwitch(language.code)}
                className={
                  language.code === i18n.language
                    ? "language active-language"
                    : "language not-active-language"
                }
                key={language.id}
              >
                {language.name}
              </li>
            );
          })}
        </ul>
      </Menu.Item>
    </Menu>
  );
};
export default MainMenu;

import React from "react";
import { Row, Card, Divider } from "antd";
import ReactHtmlParser from "react-html-parser";

const { Meta } = Card;

const BlogSingle = ({ blog }) => {
  return (
    <Row
      style={{ maxWidth: "1170px", margin: "auto" }}
      className="blog-excerpt"
      justify="space-between"
      align="top"
    >
      <Card
        bordered={false}
        style={{ width: "100%" }}
        cover={
          <img
            alt={blog.slug}
            src={blog._embedded["wp:featuredmedia"][0].source_url}
          />
        }
      >
        <Meta
          style={{ padding: "0 10%" }}
          title={blog.title.rendered}
          description={ReactHtmlParser(blog.content.rendered)}
        />
        <div style={{ padding: "0 10%" }}>
          <Divider style={{ margin: "6px 0" }} />
        </div>
      </Card>
    </Row>
  );
};

export default BlogSingle;

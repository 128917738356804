import React from "react";
import { Col, Divider, Row, Typography, Layout, Grid } from "antd";
import section1Image from "../Assets/MVRDV-section-01.jpg";
import Slide1 from "../Assets/MVRDV-slide-01.jpg";
import Slide2 from "../Assets/MVRDV-slide-02.jpg";
import Slide3 from "../Assets/MVRDV-slide-03.jpg";
import Slide4 from "../Assets/MVRDV-slide-04.jpg";
import Slide5 from "../Assets/MVRDV-slide-05.jpg";
import Slide6 from "../Assets/MVRDV-slide-06.jpg";
import Slide7 from "../Assets/MVRDV-slide-07.jpg";
import footerImage from "../Assets/footer-section.png";
import { useTranslation } from "react-i18next";
import AliceCarousel from "react-alice-carousel";

const { Footer } = Layout;
const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const MVRDVPage = () => {
  const { t } = useTranslation();
  const { xs } = useBreakpoint();

  return (
    <div className="library-page">
      <Row
        className="section first-circle-image"
        justify="space-between"
        align="middle"
        style={{
          backgroundColor: "#293B97",
          paddingBottom: 0,
        }}
      >
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Title level={1}>{t("MVRDV.Title")}</Title>
          <Divider style={{ borderTop: "3px solid rgb(255 255 255)" }} />
          <Paragraph>
            {t("MVRDV.Text1")} <br />
            {t("MVRDV.Text2")}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://mvrdv.nl/projects/800/gagarin-valley"
            >
              https://mvrdv.nl/projects/800/gagarin-valley
            </a>
            <br/>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://youtu.be/O_HpczK_67w"
            >
              https://youtu.be/O_HpczK_67w
            </a>
          </Paragraph>
        </Col>
        <Col
          xs={{ span: 24 }}
          lg={{ span: 10 }}
          style={{ alignSelf: "flex-end" }}
        >
          <img
            style={{ width: "100%", borderRadius: "50%" }}
            src={section1Image}
            alt="MVRDV"
          />
        </Col>
      </Row>
      <Row className="section home-section-01">
        <AliceCarousel
          touchTracking
          disableDotsControls
          autoPlay
          autoPlayStrategy="none"
          infinite
          autoPlayInterval={4000}
        >
          <img src={Slide1} alt="slide-1" />
          <img src={Slide2} alt="slide-2" />
          <img src={Slide3} alt="slide-3" />
          <img src={Slide4} alt="slide-4" />
          <img src={Slide5} alt="slide-4" />
          <img src={Slide6} alt="slide-4" />
          <img src={Slide7} alt="slide-4" />
        </AliceCarousel>
      </Row>
      <Footer>
        <Row
          className="section"
          style={{
            backgroundColor: "#ffffff",
            backgroundImage: `url(${footerImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            minHeight: xs ? "calc(100vh - 80px)" : "calc(100vh - 72px)",
          }}
          justify="space-between"
          align="middle"
        >
          <Col xs={{ span: 24 }} lg={{ span: 10 }}></Col>
          <Col xs={{ span: 24 }} lg={{ span: 10 }}>
            <Title level={2}>{t("Global.Contacts")}</Title>
            <Divider style={{ borderTop: "3px solid rgb(38, 38, 38)" }} />
            <Paragraph>
              <a href="mailto:mail@gagarinproject.am">mail@gagarinproject.am</a>
            </Paragraph>
            <Paragraph>
              <a href="tel:+37410501500">+374 10 501500</a>
            </Paragraph>
          </Col>
        </Row>
      </Footer>
    </div>
  );
};

export default MVRDVPage;

import React from "react";
import { Col, Divider, Row, Typography, Layout, Grid } from "antd";
import { Link } from "react-router-dom";
import section1Image from "../Assets/library-section-01.png";
import library1image from "../Assets/library-image-01.png";
import library2image from "../Assets/library-image-02.png";
import footerImage from "../Assets/footer-section.png";
import { useTranslation } from "react-i18next";

const { Footer } = Layout;
const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const LibraryPage = () => {
  const { t } = useTranslation();
  const { xs } = useBreakpoint();

  return (
    <div className="library-page">
      <Row
        className="section first-circle-image"
        justify="space-between"
        align="middle"
        style={{
          backgroundColor: "#293B97",
          paddingBottom: 0,
        }}
      >
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Title level={1}>{t("Library.Library")}</Title>
          <Divider style={{ borderTop: "3px solid rgb(255 255 255)" }} />
          <Paragraph>
            {t("Library.LibraryText1")} <br />
            {t("Library.LibraryText2")}
          </Paragraph>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10 }} style={{ alignSelf: "flex-end" }}>
          <img style={{ width: "100%" }} src={section1Image} alt="vision" />
        </Col>
      </Row>
      <Divider style={{ border: "none" }} />
      <Row
        className="section"
        style={{ minHeight: "30vh" }}
        justify="start"
        align="top"
      >
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Row justify="start" align="top" gutter={16} className="library-item">
            <Col span={8}>
              <div
                style={{
                  height: 110,
                  width: 110,
                  backgroundImage: `url(${library1image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              ></div>
            </Col>
            <Col span={16}>
              <Link
                to={{
                  pathname:
                    "http://blog.gagarinproject.am/wp-content/uploads/2022/11/GP_BOOKLET_ARMENG_NEW_WEB_PAGES.pdf",
                }}
                target="_blank"
              >
                <Paragraph style={{ color: "#262626", marginBottom: 0 }}>
                  {t("Library.Booklet1")}
                </Paragraph>
                <Paragraph style={{ color: "#707070" }}>{t("Library.PDFFile")}</Paragraph>
              </Link>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Row justify="start" align="top" gutter={16} className="library-item">
            <Col span={8}>
              <div
                style={{
                  height: 110,
                  width: 110,
                  backgroundImage: `url(${library2image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              ></div>
            </Col>
            <Col span={16}>
              <Link
                to={{
                  pathname:
                    "https://blog.gagarinproject.am/wp-content/uploads/2021/12/GP_BOOKLET_PAGES_ARMRUSS.pdf",
                }}
                target="_blank"
              >
                <Paragraph style={{ color: "#262626", marginBottom: 0 }}>
                  {t("Library.Booklet2")}
                </Paragraph>
                <Paragraph style={{ color: "#707070" }}>{t("Library.PDFFile")}</Paragraph>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer>
        <Row
          className="section"
          style={{
            backgroundColor: "#ffffff",
            backgroundImage: `url(${footerImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            minHeight: xs ? "calc(100vh - 80px)" : "calc(100vh - 72px)",
          }}
          justify="space-between"
          align="middle"
        >
          <Col xs={{ span: 24 }} lg={{ span: 10 }}></Col>
          <Col xs={{ span: 24 }} lg={{ span: 10 }}>
            <Title level={2}>{t("Global.Contacts")}</Title>
            <Divider style={{ borderTop: "3px solid rgb(38, 38, 38)" }} />
            <Paragraph>
              <a href="mailto:mail@gagarinproject.am">mail@gagarinproject.am</a>
            </Paragraph>
            <Paragraph>
              <a href="tel:+37410501500">+374 10 501500</a>
            </Paragraph>
          </Col>
        </Row>
      </Footer>
    </div>
  );
};

export default LibraryPage;

import React, { useState, useRef } from "react";
import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Modal,
  Grid,
  Layout,
} from "antd";
import section2Image from "../Assets/home-section-02.png";
import section4Image from "../Assets/home-section-04.webp";
import section5Image from "../Assets/home-section-05.png";
import { DownOutlined } from "@ant-design/icons";
import ReadMoreIcon from "../Assets/ReadMore.svg";
import ReadMoreIconGreen from "../Assets/ReadMoreGreen.svg";
import { Link } from "react-router-dom";
import CommunityPage from "./CommunityPage";
import footerImage from "../Assets/footer-section.png";
import AliceCarousel from "react-alice-carousel";
import Slide1 from "../Assets/Slide-1.jpg";
import Slide2 from "../Assets/Slide-2.jpg";
import Slide3 from "../Assets/Slide-3.jpg";
import Slide4 from "../Assets/Slide-4.jpg";
import MSlide1 from "../Assets/Mobile-Slide-1.jpg";
import MSlide2 from "../Assets/Mobile-Slide-2.jpg";
import MSlide3 from "../Assets/Mobile-Slide-3.jpg";
import MSlide4 from "../Assets/Mobile-Slide-4.jpg";
import MVRDV from "../Assets/MVRDV.jpg";
import { useTranslation } from "react-i18next";

const { Footer } = Layout;
const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const HomePage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [communityId, setCommunityId] = useState(0);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);

  const { xs } = useBreakpoint();
  const { i18n, t } = useTranslation();

  const showModal = (index) => {
    setIsModalVisible(true);
    setCommunityId(index);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const executeScroll1 = () => {
    section2.current.scrollIntoView({ block: "end", behavior: "smooth" });
  };
  const executeScroll2 = () => {
    section3.current.scrollIntoView({ block: "end", behavior: "smooth" });
  };
  const executeScroll3 = () => {
    section5.current.scrollIntoView({ block: "end", behavior: "smooth" });
  };
  const executeScroll4 = () => {
    section6.current.scrollIntoView({ block: "end", behavior: "smooth" });
  };

  return (
    <>
      <Row className="section home-section-01">
        <AliceCarousel
          touchTracking
          disableDotsControls
          autoPlay
          autoPlayStrategy="none"
          disableButtonsControls
          infinite
          autoPlayInterval={4000}
        >
          <img src={xs ? MSlide1 : Slide1} alt="slide-1" />
          <img src={xs ? MSlide2 : Slide2} alt="slide-2" />
          <img src={xs ? MSlide3 : Slide3} alt="slide-3" />
          <img src={xs ? MSlide4 : Slide4} alt="slide-4" />
        </AliceCarousel>
      </Row>
      <Row justify="center">
        <DownOutlined
          onClick={executeScroll1}
          style={{
            fontSize: 50,
            color: "#ffffff",
            position: "absolute",
            marginTop: "-80px",
          }}
        />
      </Row>
      <Row
        ref={section2}
        className="section"
        style={{
          backgroundColor: "#009547",
          backgroundImage: `url(${section2Image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
        justify="space-between"
        align="middle"
      >
        <Col xs={{ span: 24 }} lg={{ span: 14 }}></Col>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Title level={2}>Gagarin project</Title>
          <Divider style={{ borderTop: "3px solid rgb(255 255 255)" }} />
          <Paragraph>{t("Home.Text1")}</Paragraph>
          <Link to="/about">
            <Button type="text" className="read-more-btn">
              <span style={{ marginRight: 26 }}>{t("Home.ReadMore")}</span>
              <img src={ReadMoreIcon} alt="read-more-icon" />
            </Button>
          </Link>
        </Col>
      </Row>
      <Row justify="center">
        <DownOutlined
          onClick={executeScroll2}
          style={{
            fontSize: 50,
            color: "#ffffff",
            position: "absolute",
            marginTop: "-80px",
          }}
        />
      </Row>
      <Modal
        width="100%"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
        wrapClassName="modal-wrap"
      >
        <CommunityPage
          communityId={communityId}
          setCommunityId={setCommunityId}
        />
      </Modal>
      <Row
        ref={section3}
        className="section community-section"
        justify="start"
        align="bottom"
        style={{ padding: 0 }}
      >
        <Col
          className="communities gagarin"
          xs={{ span: 24 }}
          lg={{ span: 4 }}
          style={{ backgroundColor: "#293B97" }}
          onClick={() => showModal(0)}
        >
          <div className="community-title">
            <Title level={2}>{t("Home.Settlements")}</Title>
            <Divider style={{ borderTop: "3px solid rgb(255 255 255)" }} />
            <Button type="text" className="read-more-btn">
              <span style={{ marginRight: 26 }}>{t("Home.ReadMore")}</span>
              <img src={ReadMoreIcon} alt="read-more-icon" />
            </Button>
          </div>
          <Title level={3}>{t("Home.Gagarin")}</Title>
        </Col>
        <Col
          className="communities geghamavan"
          xs={{ span: 24 }}
          lg={{ span: 4 }}
          onClick={() => showModal(1)}
        >
          <Title level={3}>{t("Home.Geghamavan")}</Title>
        </Col>
        <Col
          className="communities ddmashen"
          xs={{ span: 24 }}
          lg={{ span: 4 }}
          style={{ backgroundColor: "#FFC708" }}
          onClick={() => showModal(2)}
        >
          <Title level={3}>{t("Home.Ddmashen")}</Title>
        </Col>
        <Col
          className="communities zovaber"
          xs={{ span: 24 }}
          lg={{ span: 4 }}
          onClick={() => showModal(3)}
        >
          <Title level={3}>{t("Home.Zovaber")}</Title>
        </Col>
        <Col
          className="communities tsaghkunk"
          xs={{ span: 24 }}
          lg={{ span: 4 }}
          onClick={() => showModal(4)}
        >
          <Title level={3}>{t("Home.Tsaghkunk")}</Title>
        </Col>
        <Col
          className="communities varser"
          xs={{ span: 24 }}
          lg={{ span: 4 }}
          onClick={() => showModal(5)}
        >
          <Title level={3}>{t("Home.Varser")}</Title>
        </Col>
      </Row>
      <Row
        className="section vision-section"
        style={{
          backgroundColor: "#8F78B6",
          backgroundImage: `url(${section4Image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: 0,
        }}
        align="bottom"
      >
        <div
          className="vision-title-subtitle"
          style={{ zIndex: 99, width: "100%" }}
        >
          <Title level={2} className="vision-subtitle">
            Gagarin project
          </Title>
          <Row align={xs ? "top" : "bottom"} justify="end">
            <Link to="/vision">
              <Button
                type="text"
                className="read-more-btn"
                style={{ float: "right" }}
              >
                <span style={{ marginRight: 26 }}>{t("Home.ReadMore")}</span>
                <img src={ReadMoreIcon} alt="read-more-icon" />
              </Button>
            </Link>
          </Row>
          <Row align="bottom" justify="end">
            <Paragraph
              className="vision-title"
              style={{
                fontSize:
                  i18n.language === "am"
                    ? xs
                      ? "6rem"
                      : "15rem"
                    : xs
                    ? "10rem"
                    : "18rem",
              }}
            >
              {t("Home.Vision")}
            </Paragraph>
          </Row>
        </div>
        <div className="vision-circle"></div>
      </Row>
      <Row justify="center">
        <DownOutlined
          onClick={executeScroll3}
          style={{
            fontSize: 50,
            color: "#ffffff",
            position: "absolute",
            marginTop: "-80px",
            zIndex: 99,
          }}
        />
      </Row>
      <Link to="concept">
        <Row
          ref={section5}
          justify="center"
          align="bottom"
          className="mvrdv"
        >
          <img
            style={{ width: "100%" }}
            src={MVRDV}
            alt="MVRDV"
          />
{/*           <Title style={{ marginBottom: 100, textAlign: "center" }} level={2}>
            {t("MVRDV.HomeTitle")}
          </Title> */}
        </Row>
      </Link>
      <Row justify="center">
        <DownOutlined
          onClick={executeScroll4}
          style={{
            fontSize: 50,
            color: "#ffffff",
            position: "absolute",
            marginTop: "-10%",
            zIndex: 99,
          }}
        />
      </Row>
      <Row
        ref={section6}
        className="section"
        style={{
          backgroundColor: "#FFC708",
          backgroundImage: `url(${section5Image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          alignContent: "center",
        }}
        align="middle"
      >
        <Row>
          <Title level={2}>{t("About.DARFoundation")}</Title>
        </Row>
        <Row justify="space-between">
          <Col xs={{ span: 24 }} lg={{ span: 11 }}>
            <Divider style={{ borderTop: "3px solid rgb(255 255 255)" }} />
            <Paragraph>{t("About.DARText1")}</Paragraph>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 11 }}>
            <Divider
              style={{
                borderTop: "3px solid rgb(255 255 255)",
                visibility: xs ? "hidden" : "visible",
              }}
            />
            <Paragraph>{t("About.DARText2")}</Paragraph>
          </Col>
        </Row>
        <Link to={{ pathname: "https://darfoundation.am/" }} target="_blank">
          <Button type="text" className="read-more-btn">
            <span style={{ marginRight: 26 }}>{t("Home.ReadMore")}</span>
            <img src={ReadMoreIconGreen} alt="read-more-icon" />
          </Button>
        </Link>
      </Row>
      <Footer>
        <Row
          className="section"
          style={{
            backgroundColor: "#ffffff",
            backgroundImage: `url(${footerImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            minHeight: xs ? "calc(100vh - 80px)" : "calc(100vh - 72px)",
          }}
          justify="space-between"
          align="middle"
        >
          <Col xs={{ span: 24 }} lg={{ span: 10 }}></Col>
          <Col xs={{ span: 24 }} lg={{ span: 10 }}>
            <Title level={2}>{t("Global.Contacts")}</Title>
            <Divider style={{ borderTop: "3px solid rgb(38, 38, 38)" }} />
            <Paragraph>
              <a href="mailto:mail@gagarinproject.am">mail@gagarinproject.am</a>
            </Paragraph>
            <Paragraph>
              <a href="tel:+37410501500">+374 10 501500</a>
            </Paragraph>
          </Col>
        </Row>
      </Footer>
    </>
  );
};

export default HomePage;

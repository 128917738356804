import React from "react";
import { Link } from "react-router-dom";
import { Typography, Row, Layout, Button, Col, Divider } from "antd";
import footerImage from "../Assets/footer-section.png";
import { useTranslation } from "react-i18next";

const { Content, Footer } = Layout;
const { Title, Paragraph } = Typography;

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <div id="not-found-page">
      <Content
        style={{ margin: "24px 24px", maxWidth: "100%", minHeight: "100vh" }}
      >
        <Row className="section" justify="space-around" align="middle">
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Typography className="not-found">
              <Title>404</Title>
              <Paragraph>{t("Global.PageNotFound")}</Paragraph>
              <Link to="/">
                <Button type="primary" key="console">
                  {t("Global.BackToHome")}
                </Button>
              </Link>
            </Typography>
          </Col>
        </Row>
      </Content>
      <Footer>
        <Row
          className="section"
          style={{
            backgroundColor: "#ffffff",
            backgroundImage: `url(${footerImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            minHeight: "80vh",
          }}
          justify="space-between"
          align="middle"
        >
          <Col xs={{ span: 24 }} lg={{ span: 10 }}></Col>
          <Col xs={{ span: 24 }} lg={{ span: 10 }}>
            <Title level={2}>{t("Global.Contacts")}</Title>
            <Divider style={{ borderTop: "3px solid rgb(38, 38, 38)" }} />
            <Paragraph>
              <a href="mailto:mail@gagarinproject.am">mail@gagarinproject.am</a>
            </Paragraph>
            <Paragraph>
              <a href="tel:+37410501500">+374 10 501500</a>
            </Paragraph>
          </Col>
        </Row>
      </Footer>
    </div>
  );
};

export default NotFoundPage;

import React, {useState} from "react";
import { Modal } from "antd";
import aboutSection03 from "../Assets/about-section-03.png";
import CommunityPage from "./CommunityPage";

const GagarinMap = () => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [communityId, setCommunityId] = useState(0);

	const showModal = (index) => {
		setIsModalVisible(true);
		setCommunityId(index);
	  };
	
	  const handleOk = () => {
		setIsModalVisible(false);
	  };
	
	  const handleCancel = () => {
		setIsModalVisible(false);
	  };

	  
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1920 1080"
      xmlSpace="preserve"
    >
      <image
		className="map-image"
        width="1920"
        height="1080"
        xlinkHref={aboutSection03}
      ></image>
      <g id="Group_1410" className="st0">
        <g id="Group_1409">
          <path
            id="Path_1969"
            className="st1"
            d="M1463.1,779v18.4c0,5.9-4.8,10.7-10.7,10.7c-5.9,0-10.7-4.8-10.7-10.7v-4.1l-41.2-9.9
			l-16.9,16.9c-6.7,6.7-17.7,6.7-24.4,0c0,0,0,0,0,0l-11-11c-1.9-1.9-5-1.9-6.9,0c-0.9,0.9-1.5,2.2-1.4,3.5v17.5
			c0,4.2-3.4,7.5-7.5,7.5c-2,0-3.9-0.8-5.3-2.2l-2-2c-2.5-2.5-6.5-2.5-9,0c-1.2,1.2-1.9,2.8-1.9,4.5c0,2.4-1,4.8-2.7,6.5
			c-1.7,1.7-4,2.7-6.4,2.7c-5.1,0-9.2-4-9.3-9.1c0,0,0-0.1,0-0.1v-20.8c0-19.3-15.6-34.9-34.9-34.9c0,0,0,0,0,0
			c-2.8,0-5.2-2.3-5.2-5.1c0-0.4,0-0.7,0.1-1.1l4-19.5l20.7-20.7c2.4-2.4,2.4-6.3,0-8.7l-3-3c-1.7-1.7-1.7-4.5,0-6.2
			c0.8-0.8,1.9-1.3,3.1-1.3c3.1,0,6.1-0.8,8.7-2.3c2.7-1.5,4.9-3.7,6.4-6.4c4.4-7.5,13.5-10.7,21.6-7.5l117.6,46.8
			c7.5,3,11.2,11.5,8.3,19c-2.1,5.4-0.8,11.6,3.3,15.7L1463.1,779z"
          />
        </g>
      </g>
      <g id="Group_1412" className="st0">
        <g id="Group_1411">
          <path
            id="Path_1970"
            className="st1"
            d="M1308.7,592.5v9.3c0,4.6-3.7,8.3-8.3,8.3c-2.2,0-4.3-0.9-5.8-2.4l-1.2-1.2
			c-3.2-3.2-8.5-3.2-11.7,0l-8.4,8.4h-26.4l-23.7,23.7c-4.4,4.4-10.3,6.9-16.5,6.9c-6.2,0-12.2,2.5-16.5,6.9l-4.3,4.3
			c-2.2,2.2-5.7,2.2-7.9,0c-2.2-2.2-5.7-2.2-7.9,0c-1,1-1.6,2.5-1.6,3.9v21.2h14.4c8.8,0,15.9,7.1,15.9,15.9c0,4.2-1.7,8.3-4.7,11.2
			l-17,17h-18.6l-9.4,9.4c-4.1,4.1-10.7,4.1-14.8,0l-8.2-8.2c-3.7-3.7-9.7-3.7-13.3,0l-20.7,20.7l-16.4-16.4
			c-7.1-7.1-9.1-17.9-5-27.1l18.4-41.1c1.3-2.8,2.2-5.8,2.9-8.8c0.6-3,1-6.1,1-9.2c0-11.7,4.6-22.9,12.9-31.1l17.6-17.6h22.2
			c6.2,0,11.2-5,11.2-11.2c0-8.9,7.2-16.1,16.1-16.2h0.1c4.3,0,8.4,1.7,11.4,4.7l7.8,7.8h40l28.1-28.1c8.1-8.1,21.3-8.1,29.5,0
			c3.9,3.9,6.1,9.2,6.1,14.7v11.9L1308.7,592.5z"
          />
        </g>
      </g>
      <g id="Group_1414" className="st0">
        <g id="Group_1413">
          <path
            id="Path_1971"
            className="st1"
            d="M1124,511.3v20.2c0,4-3.2,7.2-7.2,7.2h-27.6c-2.6,0-5.1,1-7,2.9c-3.9,3.9-10.1,3.9-14,0
			l-13-13h-7.9c-3.6,0-6.5-2.9-6.5-6.6c0-3.6-2.9-6.5-6.5-6.5h-16.3l-11.4,11.4c-7.3,7.3-17.2,11.4-27.5,11.4h-1.6
			c-9.9,0-17.8-8-17.8-17.9c0-4.7,1.9-9.3,5.2-12.6l6.5-6.5v-27.8l15.5-15.5V449c0-4.3,3.6-7.8,7.9-7.7c4.3,0,7.7,3.5,7.7,7.7v4.2
			c6.9,6.9,18.1,6.9,25,0l5.5-5.5c3.3-3.3,7.8-5.2,12.5-5.2h25c0-2.6,2.1-4.8,4.8-4.8c1.3,0,2.5,0.5,3.4,1.4l9.6,9.6
			c4.5,4.6,11.9,4.6,16.5,0c0,0,0,0,0,0c3.2-3.2,8.4-3.2,11.6,0c3.2,3.2,3.2,8.4,0,11.6l0,0l-5.8,5.8v26c0,1.9,0.7,3.6,2,4.9
			l9.5,9.5C1123.2,507.7,1124,509.4,1124,511.3z"
          />
        </g>
      </g>
      <g id="Group_1416" className="st0">
        <g id="Group_1415">
          <path
            id="Path_1972"
            className="st1"
            d="M1037.1,829.5v43.2c0,5.8-2.3,11.4-6.4,15.5l-4.1,4.1c-4.1,4.1-6.4,9.7-6.4,15.5
			c0,14.3-11.6,25.9-25.9,25.9c-6.9,0-13.5-2.7-18.3-7.6l-15.5-15.5c-2.7-2.7-6.3-4.2-10.1-4.2h-11.2L928.5,896
			c-3.9-3.9-3.9-10.2,0-14.1c0,0,0,0,0,0c1.9-1.9,2.9-4.4,2.9-7.1v-61.9c0-5.2,2.1-10.2,5.7-13.8l18.7-18.8V768l6.3-6.3
			c1.3-1.4,3.1-2.1,5-2.1c4,0,7.2,3.2,7.3,7.1c0,0,0,0.1,0,0.1c0,1.9,0.8,3.7,2.3,4.9l54.6,45.3
			C1034.9,820.1,1037.1,824.7,1037.1,829.5z"
          />
        </g>
      </g>
      <g id="Group_1418" className="st0">
        <g id="Group_1417">
          <path
            id="Path_1973"
            className="st1"
            d="M692,532.7c2.3,2.3,2.3,6.1,0,8.4c-1.1,1.1-2.6,1.7-4.2,1.7c-3.3,0-5.9,2.6-5.9,5.9v22.5
			c0,6.4-5.2,11.5-11.5,11.6c0,0,0,0,0,0c-6.4,0-11.5,5.2-11.5,11.5v1.4c0,6.4-5.2,11.5-11.5,11.6c0,0,0,0,0,0h-13
			c-2,0-4-0.8-5.4-2.3c-3-3-7.9-3-10.9,0c0,0,0,0,0,0l-5.2,5.2c-2.4,2.4-5.7,3.8-9.1,3.8c-7.1,0-12.9,5.8-12.9,12.9c0,0,0,0,0,0V652
			c0,4.3-3.4,7.7-7.7,7.7s-7.7-3.4-7.7-7.7v-19.4c0-3.2-2.6-5.9-5.9-5.9c0,0,0,0,0,0h-15c-3.9,0-7.7,1.6-10.5,4.3
			c-2.8,2.8-6.5,4.3-10.5,4.3h-19.3v-13.2c0-6.4-5.2-11.7-11.7-11.7c0,0,0,0,0,0c-1.5,0-3,0.3-4.5,0.9c-1.4,0.6-2.7,1.4-3.8,2.5
			l-11.8,11.8c-3.6,3.6-8.5,5.6-13.6,5.6h-8l-11,11H435l18-18c-5.4,0-9.8-4.4-9.8-9.8l0,0v-19l13.8-6.5c4.1-1.9,6.8-6.1,6.8-10.6
			c0-4.1,2.1-7.8,5.6-10l35.1-21.6h15c6.1,0,11.1-5,11.1-11.1V525l11.7-11.7c2.8-2.8,7.4-2.8,10.2,0c0,0,0,0,0,0l5.9,5.9
			c2.8,2.8,7.4,2.8,10.2,0c0,0,0,0,0,0l19.1-19.1c4.3-4.3,10.2-6.7,16.3-6.7h1.3c6.1,0,12-2.4,16.3-6.7c2.9-3,7.7-3,10.6-0.1
			c2.4,2.4,3,6.2,1.2,9.1l-19.7,34.3h42.9c2,0,3.8-0.8,5.2-2.1l7.5-7.5c2.9-2.9,7.5-2.9,10.4,0c0,0,0,0,0,0L692,532.7z"
          />
        </g>
      </g>
      <g id="Group_1420" className="st0">
        <g id="Group_1419">
          <path
            id="Path_1974"
            className="st1"
            d="M426.2,603.6c2.9,0,5.2,2.4,5.2,5.2c0,1-0.3,1.9-0.8,2.7l-0.8,0.9l-18.3,18.3h-10.7
			c-2.7,0-5,2.2-5,5c0,1.3,0.5,2.6,1.5,3.5c0.9,0.9,1.4,2.2,1.4,3.5v9.7c0,2.7-2.2,5-5,5h-12.8c-2.6,0-4.6-2.1-4.6-4.7
			c0-2.6-2.1-4.6-4.6-4.6c0,0,0,0,0,0c-1.2,0-2.4,0.5-3.3,1.4l-8.6,8.6c-0.9,0.9-2.1,1.4-3.3,1.4h-23.7c-4.1,0-8,1.6-10.9,4.5
			c-6,6-15.8,6-21.9,0l-9.6-9.6v-18.4l8.9-8.9c3.3-3.3,3.3-8.7,0-12.1l-4.3-4.3c-2.4-2.4-3.1-5.9-2-9l18.2-48.8
			c1-2.6,3.4-4.3,6.2-4.3c3.6,0,6.6-2.9,6.6-6.6c0,0,0,0,0,0v-16.2c0-6.5,5.3-11.7,11.8-11.7c3.1,0,6.1,1.2,8.3,3.4l16.4,16.4v17
			l50.1,50.1c1.7,1.7,4,2.6,6.4,2.6L426.2,603.6z"
          />
        </g>
      </g>
      <g id="Group_1478" className="st0">
        <g id="Group_1477">
          <path
            id="Path_2004"
            className="st2"
            d="M429.8,612.6l0.8-0.9C430.4,611.9,430.1,612.3,429.8,612.6z"
          />
        </g>
      </g>
      <g id="Group_1520" className="st0 varser" onClick={() => showModal(5)}>
        <path
          id="Path_2031"
          className="st1"
          d="M1463.1,779v18.4c0,5.9-4.8,10.7-10.7,10.7c-5.9,0-10.7-4.8-10.7-10.7v-4.1l-41.2-9.9
		l-16.9,16.9c-6.7,6.7-17.7,6.7-24.4,0c0,0,0,0,0,0l-11-11c-1.9-1.9-5-1.9-6.9,0c-0.9,0.9-1.5,2.2-1.4,3.5v17.5
		c0,4.2-3.4,7.5-7.5,7.5c-2,0-3.9-0.8-5.3-2.2l-2-2c-2.5-2.5-6.5-2.5-9,0c-1.2,1.2-1.9,2.8-1.9,4.5c0,2.4-1,4.8-2.7,6.5
		c-1.7,1.7-4,2.7-6.4,2.7c-5.1,0-9.2-4-9.3-9.1c0,0,0-0.1,0-0.1v-20.8c0-19.3-15.6-34.9-34.9-34.9c0,0,0,0,0,0
		c-2.8,0-5.2-2.3-5.2-5.1c0-0.4,0-0.7,0.1-1.1l4-19.5l20.7-20.7c2.4-2.4,2.4-6.3,0-8.7l-3-3c-1.7-1.7-1.7-4.5,0-6.2
		c0.8-0.8,1.9-1.3,3.1-1.3c3.1,0,6.1-0.8,8.7-2.3c2.7-1.5,4.9-3.7,6.4-6.4c4.4-7.5,13.5-10.7,21.6-7.5l117.6,46.8
		c7.5,3,11.2,11.5,8.3,19c-2.1,5.4-0.8,11.6,3.3,15.7L1463.1,779z"
        />
      </g>
      <g id="Group_1521" className="st0 geghamavan" onClick={() => showModal(1)}>
        <path
          id="Path_2032"
          className="st1"
          d="M1308.7,592.5v9.3c0,4.6-3.7,8.3-8.3,8.3c-2.2,0-4.3-0.9-5.8-2.4l-1.2-1.2
		c-3.2-3.2-8.5-3.2-11.7,0l-8.4,8.4h-26.4l-23.7,23.7c-4.4,4.4-10.3,6.9-16.5,6.9c-6.2,0-12.2,2.5-16.5,6.9l-4.3,4.3
		c-2.2,2.2-5.7,2.2-7.9,0c-2.2-2.2-5.7-2.2-7.9,0c-1,1-1.6,2.5-1.6,3.9v21.2h14.4c8.8,0,15.9,7.1,15.9,15.9c0,4.2-1.7,8.3-4.7,11.2
		l-17,17h-18.6l-9.4,9.4c-4.1,4.1-10.7,4.1-14.8,0l-8.2-8.2c-3.7-3.7-9.7-3.7-13.3,0l-20.7,20.7l-16.4-16.4
		c-7.1-7.1-9.1-17.9-5-27.1l18.4-41.1c1.3-2.8,2.2-5.8,2.9-8.8c0.6-3,1-6.1,1-9.2c0-11.7,4.6-22.9,12.9-31.1l17.6-17.6h22.2
		c6.2,0,11.2-5,11.2-11.2c0-8.9,7.2-16.1,16.1-16.2h0.1c4.3,0,8.4,1.7,11.4,4.7l7.8,7.8h40l28.1-28.1c8.1-8.1,21.3-8.1,29.5,0
		c3.9,3.9,6.1,9.2,6.1,14.7v11.9L1308.7,592.5z"
        />
      </g>
      <g id="Group_1522" className="st0 tsaghkunk" onClick={() => showModal(4)}>
        <path
          id="Path_2033"
          className="st1"
          d="M1124,511.3v20.2c0,4-3.2,7.2-7.2,7.2h-27.6c-2.6,0-5.1,1-7,2.9c-3.9,3.9-10.1,3.9-14,0l-13-13
		h-7.9c-3.6,0-6.5-2.9-6.5-6.6c0-3.6-2.9-6.5-6.5-6.5h-16.3l-11.4,11.4c-7.3,7.3-17.2,11.4-27.5,11.4h-1.6c-9.9,0-17.8-8-17.8-17.9
		c0-4.7,1.9-9.3,5.2-12.6l6.5-6.5v-27.8l15.5-15.5V449c0-4.3,3.6-7.8,7.9-7.7c4.3,0,7.7,3.5,7.7,7.7v4.2c6.9,6.9,18.1,6.9,25,0
		l5.5-5.5c3.3-3.3,7.8-5.2,12.5-5.2h25c0-2.6,2.1-4.8,4.8-4.8c1.3,0,2.5,0.5,3.4,1.4l9.6,9.6c4.5,4.6,11.9,4.6,16.5,0c0,0,0,0,0,0
		c3.2-3.2,8.4-3.2,11.6,0c3.2,3.2,3.2,8.4,0,11.6l0,0l-5.8,5.8v26c0,1.9,0.7,3.6,2,4.9l9.5,9.5C1123.2,507.7,1124,509.4,1124,511.3z
		"
        />
      </g>
      <g id="Group_1523" className="st0 gagarin" onClick={() => showModal(0)}>
        <path
          id="Path_2034"
          className="st1"
          d="M1037.1,829.5v43.2c0,5.8-2.3,11.4-6.4,15.5l-4.1,4.1c-4.1,4.1-6.4,9.7-6.4,15.5
		c0,14.3-11.6,25.9-25.9,25.9c-6.9,0-13.5-2.7-18.3-7.6l-15.5-15.5c-2.7-2.7-6.3-4.2-10.1-4.2h-11.2L928.5,896
		c-3.9-3.9-3.9-10.2,0-14.1c0,0,0,0,0,0c1.9-1.9,2.9-4.4,2.9-7.1v-61.9c0-5.2,2.1-10.2,5.7-13.8l18.7-18.8V768l6.3-6.3
		c1.3-1.4,3.1-2.1,5-2.1c4,0,7.2,3.2,7.3,7.1c0,0,0,0.1,0,0.1c0,1.9,0.8,3.7,2.3,4.9l54.6,45.3
		C1034.9,820.1,1037.1,824.7,1037.1,829.5z"
        />
      </g>
      <g id="Group_1524" className="st0 ddmashen" onClick={() => showModal(2)}>
        <path
          id="Path_2035"
          className="st1"
          d="M692,532.7c2.3,2.3,2.3,6.1,0,8.4c-1.1,1.1-2.6,1.7-4.2,1.7c-3.3,0-5.9,2.6-5.9,5.9v22.5
		c0,6.4-5.2,11.5-11.5,11.6c0,0,0,0,0,0c-6.4,0-11.5,5.2-11.5,11.5v1.4c0,6.4-5.2,11.5-11.5,11.6c0,0,0,0,0,0h-13
		c-2,0-4-0.8-5.4-2.3c-3-3-7.9-3-10.9,0c0,0,0,0,0,0l-5.2,5.2c-2.4,2.4-5.7,3.8-9.1,3.8c-7.1,0-12.9,5.8-12.9,12.9c0,0,0,0,0,0V652
		c0,4.3-3.4,7.7-7.7,7.7s-7.7-3.4-7.7-7.7v-19.4c0-3.2-2.6-5.9-5.9-5.9c0,0,0,0,0,0h-15c-3.9,0-7.7,1.6-10.5,4.3
		c-2.8,2.8-6.5,4.3-10.5,4.3h-19.3v-13.2c0-6.4-5.2-11.7-11.7-11.7c0,0,0,0,0,0c-1.5,0-3,0.3-4.5,0.9c-1.4,0.6-2.7,1.4-3.8,2.5
		l-11.8,11.8c-3.6,3.6-8.5,5.6-13.6,5.6h-8l-11,11H435l18-18c-5.4,0-9.8-4.4-9.8-9.8l0,0v-19l13.8-6.5c4.1-1.9,6.8-6.1,6.8-10.6
		c0-4.1,2.1-7.8,5.6-10l35.1-21.6h15c6.1,0,11.1-5,11.1-11.1V525l11.7-11.7c2.8-2.8,7.4-2.8,10.2,0c0,0,0,0,0,0l5.9,5.9
		c2.8,2.8,7.4,2.8,10.2,0c0,0,0,0,0,0l19.1-19.1c4.3-4.3,10.2-6.7,16.3-6.7h1.3c6.1,0,12-2.4,16.3-6.7c2.9-3,7.7-3,10.6-0.1
		c2.4,2.4,3,6.2,1.2,9.1l-19.7,34.3h42.9c2,0,3.8-0.8,5.2-2.1l7.5-7.5c2.9-2.9,7.5-2.9,10.4,0c0,0,0,0,0,0L692,532.7z"
        />
      </g>
      <g id="Group_1525" className="st0 zovaber" onClick={() => showModal(3)}>
        <path
          id="Path_2036"
          className="st1"
          d="M426.2,603.6c2.9,0,5.2,2.4,5.2,5.2c0,1-0.3,1.9-0.8,2.7l-0.8,0.9l-18.3,18.3h-10.7
		c-2.7,0-5,2.2-5,5c0,1.3,0.5,2.6,1.5,3.5c0.9,0.9,1.4,2.2,1.4,3.5v9.7c0,2.7-2.2,5-5,5h-12.8c-2.6,0-4.6-2.1-4.6-4.7
		c0-2.6-2.1-4.6-4.6-4.6c0,0,0,0,0,0c-1.2,0-2.4,0.5-3.3,1.4l-8.6,8.6c-0.9,0.9-2.1,1.4-3.3,1.4h-23.7c-4.1,0-8,1.6-10.9,4.5
		c-6,6-15.8,6-21.9,0l-9.6-9.6v-18.4l8.9-8.9c3.3-3.3,3.3-8.7,0-12.1l-4.3-4.3c-2.4-2.4-3.1-5.9-2-9l18.2-48.8
		c1-2.6,3.4-4.3,6.2-4.3c3.6,0,6.6-2.9,6.6-6.6c0,0,0,0,0,0v-16.2c0-6.5,5.3-11.7,11.8-11.7c3.1,0,6.1,1.2,8.3,3.4l16.4,16.4v17
		l50.1,50.1c1.7,1.7,4,2.6,6.4,2.6L426.2,603.6z"
        />
      </g>
	  <Modal
          width="100%"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <CommunityPage
            communityId={communityId}
            setCommunityId={setCommunityId}
          />
        </Modal>
    </svg>
  );
};

export default GagarinMap;

import React from "react";
import { Col, Divider, Row, Typography, Grid, Layout } from "antd";
import visionImage from "../Assets/vision-image-1.jpg";
import section2Image from "../Assets/vision-section-02.png";
import section3Image from "../Assets/vision-section-03.webp";
import section4Image from "../Assets/vision-section-04.png";
import section41Image from "../Assets/vision-section-04-1.png";
import section5Image from "../Assets/vision-section-05.webp";
import section6Image from "../Assets/vision-section-06.png";
import section7Image from "../Assets/vision-section-07.png";
import section8Image from "../Assets/vision-section-08.png";
import footerImage from "../Assets/footer-section.png";
import { useTranslation } from "react-i18next";

const { Footer } = Layout;
const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const VisionPage = () => {
  const { xs } = useBreakpoint();
  const { t } = useTranslation();

  const projectFundamentals = [
    `${t("Vision.ProjectFundamentals1")}`,
    `${t("Vision.ProjectFundamentals2")}`,
    `${t("Vision.ProjectFundamentals3")}`,
    `${t("Vision.ProjectFundamentals4")}`,
    `${t("Vision.ProjectFundamentals5")}`,
    `${t("Vision.ProjectFundamentals6")}`,
    `${t("Vision.ProjectFundamentals7")}`,
  ];

  const objectives = [
    `${t("Vision.Objectives1")}`,
    `${t("Vision.Objectives2")}`,
    `${t("Vision.Objectives3")}`,
    `${t("Vision.Objectives4")}`,
    `${t("Vision.Objectives5")}`,
    `${t("Vision.Objectives6")}`,
    `${t("Vision.Objectives7")}`,
  ];

  const clean = [
    `${t("Vision.Clean1")}`,
    `${t("Vision.Clean2")}`,
    `${t("Vision.Clean3")}`,
    `${t("Vision.Clean4")}`,
    `${t("Vision.Clean5")}`,
    `${t("Vision.Clean6")}`,
    `${t("Vision.Clean7")}`,
  ];

  const management = [
    `${t("Vision.ManagementText2")}`,
    `${t("Vision.ManagementText3")}`,
  ];

  return (
    <div className="vision-page">
      <Row
        className="section first-circle-image"
        justify="space-between"
        align="middle"
        style={{
          backgroundColor: "#8F78B6",
          paddingBottom: 0,
        }}
      >
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Title level={1}>{t("Vision.VisionTitle")}</Title>
          <Divider style={{ borderTop: "3px solid rgb(255 255 255)" }} />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10 }} style={{ alignSelf: "flex-end" }}>
          <img style={{ width: "100%", borderRadius: "50%" }} src={visionImage} alt="vision" />
        </Col>
      </Row>
      <Row
        className="section"
        justify="space-between"
        align="middle"
        style={{ minHeight: "auto", paddingTop: xs ? 75 : 150 }}
      >
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Divider style={{ borderTop: "3px solid #293B97" }} />
          <Paragraph>{t("Vision.Text1")}</Paragraph>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Paragraph>{t("Vision.Text2")}</Paragraph>
        </Col>
      </Row>
      <Row
        className="section"
        style={{ minHeight: "auto", paddingTop: 0, paddingBottom: 0 }}
      >
        <Divider style={{ borderTop: "3px solid #293B97", margin: 0 }} />
      </Row>
      <Row
        className="section"
        style={{
          backgroundImage: `url(${section2Image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "bottom right",
        }}
        justify="space-between"
        align="middle"
      >
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Title level={2}>{t("Vision.ProjectFundamentals")}</Title>
          <ol>
            {projectFundamentals.map((row, index) => (
              <li key={index}>{row}</li>
            ))}
          </ol>
          <Divider style={{ borderTop: "none", marginTop: xs ? 25 : 75 }} />
          <Paragraph>{t("Vision.Text3")}</Paragraph>
          <Paragraph>{t("Vision.Text4")}</Paragraph>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 14 }}></Col>
      </Row>
      <Row
        className="section white-text main-directions"
        justify="space-between"
        align="middle"
        style={{
          backgroundColor: "#8F78B6",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: 0,
        }}
      >
        <Col xs={{ span: 24 }} lg={{ span: 15 }}>
          <div
            style={{
              backgroundColor: "#8F78B6",
              minHeight: "100vh",
              backgroundImage: `url(${section3Image})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <div
            className="main-directions"
            style={{
              backgroundColor: "#8F78B6",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Title level={2}>{t("Vision.MainDirections")}</Title>
            <Paragraph>{t("Vision.MainDirectionsText")}</Paragraph>
            <Paragraph>1. {t("Vision.MainDirectionsText1")}</Paragraph>
            <Paragraph>2. {t("Vision.MainDirectionsText2")}</Paragraph>
            <Paragraph>3. {t("Vision.MainDirectionsText3")}</Paragraph>
          </div>
        </Col>
      </Row>
      <Row
        className="section"
        justify="space-around"
        align="top"
        style={{
          paddingTop: xs ? 35 : 75,
          paddingBottom: xs ? 35 : 75,
          backgroundImage: `url(${section41Image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "bottom right",
        }}
      >
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Title level={2}>{t("Vision.EducationEcosystem")}</Title>
          <Paragraph>{t("Vision.EducationEcosystemText1")}</Paragraph>
          <Paragraph>{t("Vision.EducationEcosystemText2")}</Paragraph>
          <Paragraph>{t("Vision.EducationEcosystemText3")}</Paragraph>
          <Paragraph>{t("Vision.EducationEcosystemText4")}</Paragraph>
          <Paragraph>{t("Vision.EducationEcosystemText5")}</Paragraph>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <img
            style={{ width: "100%" }}
            src={section4Image}
            alt="visionimage"
          />
        </Col>
      </Row>
      <Row
        className="section"
        justify="space-around"
        align="middle"
        style={{
          backgroundImage: `url(${section5Image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "70vh",
        }}
      ></Row>
      <Row
        className="section"
        justify="space-around"
        align="middle"
        style={{
          paddingTop: xs ? 35 : 75,
          backgroundImage: `url(${section6Image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "right top",
        }}
      >
        <Col xs={{ span: 24 }} lg={{ span: 18 }}>
          <Title level={2}>{t("Vision.Environment")}</Title>
          <Paragraph>{t("Vision.EnvironmentText1")}</Paragraph>
          <Paragraph>{t("Vision.EnvironmentText2")}</Paragraph>
          <Paragraph>{t("Vision.EnvironmentText3")}</Paragraph>
          <Paragraph>{t("Vision.EnvironmentText4")}</Paragraph>
          <Divider
            style={{
              borderTop: "3px solid #293B97",
              marginTop: 35,
              marginBottom: 35,
            }}
          />
          <Paragraph style={{ fontWeight: 900 }}>
            {t("Vision.Objectives")}
          </Paragraph>
          <ol>
            {objectives.map((row, index) => (
              <li key={index}>{row}</li>
            ))}
          </ol>
          <Divider
            style={{ borderTop: "none", marginTop: 35, marginBottom: 35 }}
          />
          <Paragraph>{t("Vision.Clean")}</Paragraph>
          <ul>
            {clean.map((row, index) => (
              <li key={index}>{row}</li>
            ))}
          </ul>
          <Divider style={{ borderTop: "none", marginTop: 35 }} />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 4 }}></Col>
      </Row>
      <Row
        className="section"
        justify="space-around"
        align="top"
        style={{
          paddingTop: xs ? 35 : 75,
          paddingBottom: xs ? 35 : 75,
          backgroundColor: "#E8E8E8",
          minHeight: "auto",
        }}
      >
        <Col xs={{ span: 24 }} lg={{ span: 18 }}>
          <Title level={2}>{t("Vision.Sustainable")}</Title>
          <Paragraph>{t("Vision.SustainableText1")}</Paragraph>
          <Divider style={{ borderTop: "none", marginTop: 35 }} />
          <Paragraph>{t("Vision.SustainableText2")}</Paragraph>
          <Paragraph>{t("Vision.SustainableText3")}</Paragraph>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 4 }}>
          <div
            style={{
              backgroundImage: `url(${section7Image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "right top",
              width: "100%",
              height: "300px",
            }}
          ></div>
        </Col>
      </Row>
      <Row
        className="section white-text"
        justify="space-around"
        align="top"
        style={{
          paddingTop: xs ? 35 : 75,
          paddingBottom: xs ? 35 : 75,
          backgroundColor: "#8F78B6",
          backgroundImage: `url(${section8Image})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center bottom",
          backgroundSize: "auto",
        }}
      >
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Paragraph>{t("Vision.InGeneral1")}</Paragraph>
          <Paragraph>{t("Vision.InGeneral2")}</Paragraph>
          <Divider style={{ borderTop: "none", marginTop: 35 }} />
          <Paragraph>{t("Vision.InGeneral3")}</Paragraph>
          <Paragraph>{t("Vision.InGeneral4")}</Paragraph>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}></Col>
      </Row>
      <Row
        className="section"
        justify="space-around"
        align="top"
        style={{
          paddingTop: xs ? 35 : 75,
          paddingBottom: xs ? 35 : 75,
          backgroundColor: "#E8E8E8",
          minHeight: "auto",
        }}
      >
        <Col xs={{ span: 24 }} lg={{ span: 18 }}>
          <Title level={2}>{t("Vision.Management")}</Title>
          <Paragraph>{t("Vision.ManagementText1")}</Paragraph>
          <Divider style={{ borderTop: "none", marginTop: 35 }} />
          <ul>
            {management.map((row, index) => (
              <li key={index}>{row}</li>
            ))}
          </ul>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 4 }}>
          <div
            style={{
              backgroundImage: `url(${section7Image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "right top",
              width: "100%",
              height: "300px",
            }}
          ></div>
        </Col>
      </Row>
      <Footer>
        <Row
          className="section"
          style={{
            backgroundColor: "#ffffff",
            backgroundImage: `url(${footerImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            minHeight: xs ? "calc(100vh - 80px)" : "calc(100vh - 72px)",
          }}
          justify="space-between"
          align="middle"
        >
          <Col xs={{ span: 24 }} lg={{ span: 10 }}></Col>
          <Col xs={{ span: 24 }} lg={{ span: 10 }}>
            <Title level={2}>{t("Global.Contacts")}</Title>
            <Divider style={{ borderTop: "3px solid rgb(38, 38, 38)" }} />
            <Paragraph>
              <a href="mailto:mail@gagarinproject.am">mail@gagarinproject.am</a>
            </Paragraph>
            <Paragraph>
              <a href="tel:+37410501500">+374 10 501500</a>
            </Paragraph>
          </Col>
        </Row>
      </Footer>
    </div>
  );
};

export default VisionPage;

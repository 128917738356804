import React, { useEffect, useState } from "react";
import { Row, Card, Divider, Spin, Modal } from "antd";
import AliceCarousel from "react-alice-carousel";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import { CaretRightFilled, LoadingOutlined } from "@ant-design/icons";
import BlogSingle from "./BlogSinge";

const { Meta } = Card;

const BlogArchive = () => {
  const [archive, setArchive] = useState([]);
  const [spinning, setSpinning] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [blog, setBlog] = useState({});

  const showModal = (blog) => {
    setIsModalVisible(true);
    setBlog(blog);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const getPosts = () => {
      axios
        .get(
          `https://blog.gagarinproject.am/wp-json/wp/v2/posts?_embed`
        )
        .then((res) => {
          setArchive(res.data);
          setTimeout(() => setSpinning(false), 700);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getPosts();
  }, []);

  return (
    <div className="blog-archive">
      <Spin
        spinning={spinning}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Row
          style={{ maxWidth: "1170px" }}
          className="section"
          justify="space-between"
          align="middle"
        >
          <AliceCarousel
            touchTracking
            disableDotsControls
            responsive={{
              0: {
                items: 1,
              },
              1024: { items: 4 },
            }}
            items={archive.map((blog) => (
              <Card
                bordered={false}
                key={blog.id}
                style={{ width: "100%", padding: "0 6px" }}
                cover={
                  <img
                    onClick={() => showModal(blog)}
                    className="blog-image"
                    alt="example"
                    src={blog._embedded["wp:featuredmedia"][0].source_url}
                  />
                }
              >
                <div onClick={() => showModal(blog)}>
                  <Meta title={blog.title.rendered} />
                </div>
              </Card>
            ))}
          />
        </Row>
        <Row
          style={{ maxWidth: "1170px", margin: "auto" }}
          className="blog-excerpt"
          justify="space-between"
          align="middle"
        >
          {archive.map((blog) => (
            <Card
              bordered={false}
              key={blog.id}
              style={{ width: "100%" }}
              cover={
                <img
                  alt="example"
                  src={blog._embedded["wp:featuredmedia"][0].source_url}
                />
              }
            >
              <Meta
                style={{ padding: "0 10%" }}
                title={
                  <div onClick={() => showModal(blog)}>
                    {blog.title.rendered}
                  </div>
                }
                description={ReactHtmlParser(
                  blog.excerpt.rendered.substring(
                    0,
                    blog.excerpt.rendered.indexOf("<a")
                  )
                )}
              />
              <div style={{ padding: "0 10%" }}>
                <Divider style={{ margin: "6px 0" }} />
                <div className="read-more" onClick={() => showModal(blog)}>
                  <CaretRightFilled />
                  Read Now
                </div>
              </div>
            </Card>
          ))}
        </Row>
      </Spin>
      <Modal
        visible={isModalVisible}
        width="100%"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
        wrapClassName="blog-modal-wrap"
      >
        <BlogSingle blog={blog} />
      </Modal>
    </div>
  );
};

export default BlogArchive;
